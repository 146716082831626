.home-list {
  .list__item {
    margin-top: -10%;
    @media (--phone){
      margin-top: 0;

      &:first-child {
        margin-top: -6em;
      }
    }

    &:nth-child(even) {
      transform: translateY(-10%);
      @media (--phone){
        transform: none;
      }
    }
  }
}
