.downloads {
  & &__list {
    overflow: hidden;
    max-height: 0;
    padding: .4em 1em 0 !important;
    margin-bottom: 0 !important;
    transition: max-height .4s;
  }

  &_active &__list {
    max-height: 10em;
  }
}
