.btn {
  &&_themes_square {
    padding: .3em 2em;
  }

  &&_themes_square&_themes_square_red {
    background-color: var(--red);
    border: 1px solid var(--red);
    color: var(--color__white);

    &:hover {
      background-color: transparent;
      color: var(--red);
    }
  }
}
