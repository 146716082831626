.colors {
  &_blue {
    color: var(--color__blue);
  }
  &_gray {
    color: var(--color__gray);
  }
  &_white {
    color: var(--color__white);
  }
}
