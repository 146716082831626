.img {
  &_full {
    .wpb_wrapper,
    .wpb_wrapper > .vc_single_image-wrapper{
      display: block;
    }
    .vc_single_image-img {
      width: 100%;
      height: auto;
    }
  }
}
