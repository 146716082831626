.woocommerce {
  &.archive {
    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-notices-wrapper,
    .woocommerce-ordering {
      display: none;
    }

    #primary {
      float: none;
      width: 100%;
    }

    .site-main {
      padding-bottom: 3em;
    }

    .woocommerce-pagination {
      text-align: right;
    }

    .woocommerce-products-header {
      margin-top: 1em;

      .archive-title {
        padding: 1em 0 .5em;
        border-bottom: 2px solid var(--bd__gray);
        text-align: center;
        text-transform: uppercase;
        font: 400 2rem/1.2 Montserrat, sans-serif;
        color: var(--color__blue);
        @media (--phone){
          padding-top: 2em;
        }
      }

      .page-description {
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 8em;
        border: 2px solid white;
        transform: translate(-50%, -75%);
        @media (--phone){
          max-width: 12em;
          transform: translate(-50%, -60%);
        }
      }
    }

    .products {
      .product {
        float: none !important;
        width: 100% !important; 
        padding: 2em;
        margin-bottom: 0;
        border-bottom: 2px solid var(--bd__gray);

        &:last-child {
          border-bottom: none;
        }

        &__img {
          width: 30%;
          @media (--phone){
            width: 100%;
          }
        }

        &__detail {
          width: 65%;
          text-align: left;
          @media (--phone){
            width: 100%;
            margin-top: 1em;
          }

          p {
            color: var(--color__black) !important;
          }
        }

        &__downloads {
          margin-top: 2em;
        }
      }

      .woocommerce-LoopProduct-link {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

      .woocommerce-loop-product__title {
        text-transform: capitalize;
        font: 700 1.2rem/1.2 Montserrat, sans-serif;
        color: var(--color__blue);
      }
    }

    .woocommerce-pagination {
      ul li a,
      ul li span {
        font-size: 14px;
      }
    }
  }
}
