.downloads {
  & &__title {
    position: relative;
    padding: .2em 0 .2em 3em;
    background: linear-gradient(90deg, var(--bd__gray) 50%, transparent 0);
    text-transform: capitalize;
    z-index: var(--zIndex__bottom);
    cursor: pointer;

    &:before,
    &:after {
      top: calc( 50% - 1px);
    }

    &:before {
      content: '';
      position: absolute;
      left: 1em;
      width: .5em;
      height: .5em;
      border-right: 2px solid var(--bg__blue);
      border-bottom: 2px solid var(--bg__blue);
      transform: translateY(-.3em) rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      left: 40%;
      height: 2px;
      background-color: var(--bg__blue);
    }
  }

  &&_active &__title {
    &:before {
      width: .7em;
      height: 2px;
      border-right: none;
      border-bottom: none;
      background-color: var(--bg__blue);
      transform: translateY(0) rotate(0);
    }
  }
}
