.search {
  #primary {
    float: none;
    width: 100%;
  }

  .post-wrap {
    padding: 1em 0 3em;
  }

  .page-header {
    > h3 {
      font: 400 1rem/1.2 Montserrat, sans-serif;

      > span {
        font-weight: 700;
        font-size: 1.5em;
        color: var(--color__blue);
      }
    }
  }

  .title-post {
    font: 400 1rem/1.2 Montserrat, sans-serif;
  }

  .entry-post {
    font: 400 16px/1.2 Montserrat, sans-serif;
    @media (--phone){
      font-size: 12px;
    }
  }

  .nav-links {
    overflow: hidden;
    margin-top: 2em;
    font: 700 1rem/1.2 Montserrat, sans-serif;
    text-transform: uppercase;
  }
}
