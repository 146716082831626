.title {
  &&_themes_square {
    display: inline-block;
    position: relative;
    z-index: var(--zIndex__bottom);
    line-height: 3;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -1.5em;
      bottom: 0;
      width: 100vw;
      background-color: var(--bg__gray_light);
      z-index: -1;
    }
  }
}
