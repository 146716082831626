.site-footer {
  &&_blue {
    background-color: var(--bg__blue);
    color: white;

    a {
      color: white;
    }
  }
}
