.banner {
  & &__btn {
    position: absolute;
    top: 4em;
    right: 2em;
    padding: .6em 1.5em;
    background: linear-gradient(transparent, transparent 47%, color( var(--blue) b(+2%) ), transparent 53%, transparent),
      linear-gradient(90deg, color( var(--blue) b(+20%) ), var(--blue), color( var(--blue) b(+20%) ));
    border: 1px solid black;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    color: var(--color__white);
    animation: rotate 1.5s .5s;
    @media (--phone){
      top: 1em;
      right: 2em;
      font-size: 12px;
    }

    &:hover {
      background: linear-gradient(90deg, color( white b(+60%) ), color( white b(+5%) ) 20%, color( white b(+5%) ) 80%, color( white b(+60%) ));
      color: var(--color__blue);
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0);
  }
}
