.site-footer {
    &__copyright {
        font: .65rem/1 Arial, serif;
        @media (--phone) {
            text-align: center;
            line-height: 1.4;
        }
    }

    &__inspiren {
        float: right;

        @media (--phone) {
            float: none;
            display: block;
        }
    }
}
