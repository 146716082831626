.Rich_Web_VSVT1_Main {
  top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;

  .jssort01-99-66 {
    background-color: var(--bg__gray_light) !important;

    > div {
      padding-top: 0 !important;
    }
  }

  .Rich_Web_VS_VTS_Play1,
  .Rich_Web_VS_VTS_Title1 {
    display: none;
  }
}
