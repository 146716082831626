.banner {
  & &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (--phone){
      width: 90%;
    }

    .wpb_single_image {
      width: 50px;
      height: 50px;
      padding-right: 10px;
      border-right: 2px solid white;
      margin-right: 10px;
      filter: grayscale(1) brightness(2);
      @media (--phone){
        filter: none;
      }
    }

    .wpb_column.vc_col-sm-4 > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;
      @media (--phone){
        display: block;
        padding-left: 15px;
        padding-right: 15px;

        .text,
        .title {
          color: var(--color__black);
        }
      }
    }

    @media (--phone){
      .wpb_column.vc_col-sm-4 {
        margin-top: 1em;
      }
    }
  }
}
