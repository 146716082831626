.downloads {
  & &__item {
    display: inline-block;
    width: 50%;
    min-width: 10em;
    margin-top: 1em;
    text-transform: capitalize;
    font: 400 14px/1.2 Montserrat, sans-serif;
    @media (--phone){
      width: 100%;
    }

    i {
      display: inline-block;
      margin-right: 1em;
    }
  }
}
