.search-form {
  display: inline-block;
  position: relative;
  max-width: 15em;

  label {
    margin-bottom: 0;
  }

  .search-field {
    height: 2em;
    padding-left: .6em;
    border-radius: .4em;
    border-color: color( var(--color__gray) l(+20%));
    background-color: transparent;
    font: 400 1rem/1.2 Montserrat, sans-serif;
    color: var(--color__gray);

    &::placeholder {
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }
  }

  .search-submit {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 0;
    width: 18px;
    height: 100%;
    border: none;
    background: url(../images/icon_search@1x.png) center/contain no-repeat;
    font-size: 0;
  }
}
