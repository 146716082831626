.btn {
  &&_themes_border {
    padding: .3em 2em;
    border: 1px solid;
    border-radius: 4em;
  }

  &&_themes_border&_themes_border_blue {
    border-color: var(--blue);
    color: var(--blue);

    &:hover {
      border-color: var(--blue);
      color: var(--color__black);
    }
  }
}
