.banner {
  position: relative;

  .img_full {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: color(var(--blue) a(.4));
    }
  }
  
  .vc_col-sm-12 {
    position: static;
  }
}
