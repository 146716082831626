.footer-widgets {
  padding: 1.5em 0;

  .widget {
    & .widget-title {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

  .textwidget {
    font-weight: 500;
    font-size: .8rem;

    b {
      font-size: 1rem;
    }
  }

  address {
    margin-bottom: 0;
  }

  .sidebar-column:last-child {
    .widget {
      margin-bottom: 0;
    }

    .widget-title {
      text-align: center;
    }
  }

  .wpcf7 {
    width: 100%;

    @define-mixin formInput {
      width: 100%;
      margin-top: 4px;
      background-color: transparent;
      border-radius: .5em;
      border-color: white;
      color: white;

      &:focus {
        outline: 0;
      }

      &::placeholder {
        opacity: 1;
        color: white;
      }
    }

    input {
      &[type="email"],
      &[type="text"],
      &[type="tel"] {
        @mixin formInput;
      }

      &[type="email"] {
        margin-left: 0;
      }

      &[name="your-name"],
      &[name="tel-373"] {
        width: calc(50% - 2px);
      }

      &[name="tel-373"] {
        margin-left: 4px;
      }

      &[type="submit"] {
        display: block;
        margin: 10px auto 0;
        padding: .4em 2em;
        border-radius: .8em;
        background-color: var(--bg__gray_light);
        font-weight: 500;
        color: var(--color__blue);
        text-transform: capitalize;
      }
    }

    textarea {
      @mixin formInput;
    }
  }
}
