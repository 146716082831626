#mainnav {
  margin-top: .5em;

  .menu {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .menu-item {
    float: left;

    &:first-child {
      padding-left: 0;
    }

    &:hover {
      > a {
        flex-wrap: 700 !important;
      }

      .sub-menu {
        left: 0;
      }
    }

    > a {
      font-weight: 400;
    }

    &.current_page_item,
    &.current-menu-item,
    &.current-page-ancestor,
    &.current-menu-ancestor,
    &.current-menu-parent,
    &.current-page-parent,
    &.current_page_parent,
    &.current_page_ancestor{
      > a {
        font-weight: 700;
        color: var(--blue);
      }
    }
  }

  .sub-menu {
    left: 0;

    &,
    &:hover {
      width: auto;
    }

    &:after {
      display: none;
    }

    .menu-item { 
      > a {
        width: 10em;
        border-top: none;
        background-color: white;
        line-height: 1.2;
        font-size: 14px;
        color: var(--color__blue);

        &:hover {
          background-color: color(white a(.9));  
          color: var(--color__blue);
        }
      }
    }
  }

  &-mobi {
    margin-top: 2.4em;
  }
}
