@define-mixin formCommons {
  border-radius: .6em;
  font: 500 14px/1.2 Montserrat, serif;
}
@define-mixin formInput {
  margin-top: 1em;
  @mixin formCommons;
  border-color: var(--color__blue);
  background-color: transparent;
  color: var(--color__blue);

  &::placeholder {
    opacity: 1;
    color: var(--color__blue);
  }
  &:-ms-placeholder {
    opacity: 1;
    color: var(--color__blue);
  }
  &::-ms-placeholder {
    opacity: 1;
    color: var(--color__blue);
  }
}
.wpcf7 {
  input {
    &[type="text"],
    &[type="tel"],
    &[type="date"],
    &[type="email"] {
      height: 3em;
      padding-left: 1em;
      @mixin formInput;
      width: calc(( 100% - 2em) / 3 );
      @media (--phone){
        width: 100%;
      }
    }

    &[type="date"],
    &[name="address"] {
      width: calc(100% / 3 * 2 - .5em);
      @media (--phone){
        width: 100%;
      }
    }

    &[type="tel"],
    &[name="model"],
    &[type="date"],
    &[type="email"] {
      margin-left: 1em;
      @media (--phone){
        margin-left: 0;
      }
    }

    &[type="file"] {
      margin-top: 1em;
      font: 500 14px/1.2 Montserrat, serif;
      color: var(--color__blue);
    }

    &[type="submit"],
    &[type="reset"] {
      padding: .4em 1em;
      margin-top: 1em;
      @mixin formCommons;
    }

    &[type="reset"] {
      background-color: transparent;
      color: var(--color__blue);

      &:hover {
        border-color: transparent;
      }
    }
  }

  textarea {
    padding-left: 1em;
    padding-top: 1em;
    @mixin formInput;
    resize: none;
  }
}
