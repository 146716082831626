.ul {
  padding-left: 1.5em;
  list-style: none;

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: .5em;
      left: -1.2em;
      width: 7px;
      height: 3px;
      background-color: var(--bg__blue);
    }
  }
}
