@media (--ipad){
  .header-clone {
    display: block;
  }
}
@media (--ipad_mini){
  .header-clone {
    display: block;
  }
}
@media (--phone){
  .header-clone {
    display: block;
  }
}
.site-header {
  padding: 0;
  background-color: var(--blue) !important;

  &.fixed,
  &.float-header {
    padding: 0;
    background-color: var(--blue) !important;
    @media (--ipad){
      position: fixed !important;
    }
    @media (--ipad_mini){
      position: fixed !important;
    }
    @media (--phone){
      position: fixed !important;
    }
  }

  .header-wrap,
  .header-nav {
    padding: .6em 0;
  }

  .header-nav {
    background-color: var(--gray);
    text-transform: capitalize;
  }

  &__btn {
    padding: 4px;
    vertical-align: top;
    background-color: var(--red);
    border-radius: .6em;
    line-height: 2.7;
    color: var(--color__white);
  }
}
